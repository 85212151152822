@import "core/bootstrap_mini";
@import "core/fonts";
@import "core/variables";
@import "core/slick";
@import "core/ekko_lightbox";
body {
  font-family: "SVN-Gilroy", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $primary-dark;
}

.header {
  &__top {
    padding: 20px 0px;
    .logo {
      img {
        width: 100%;
      }
    }
    .search {
      height: 100%;
      form {
        height: 100%;
        .d-flex {
          align-items: center;
          height: 100%;
        }
        .form-group {
          margin-bottom: 0px;
          width: 85%;
          .form-control {
            font-size: 14px;
            padding: 5px 10px;
            line-height: 24px;
            border-radius: 6px 0px 0px 6px;
            border-right: 0;
            height: 40px;
            &:focus {
              box-shadow: none;
              border: 1px solid #87c80a;
              border-right: none;
            }
          }
        }
        button {
          height: 40px;
          box-shadow: none;
          border: 1px solid #87c80a;
          background-color: #87c80a;
          width: 15%;
          border-radius: 0px 6px 6px 0px;
          position: relative;
          transition: 0.5s;
          &:hover {
            border: 1px solid $primary-main;
            background-color: $primary-main;
          }
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 16px;
            transition: 0.5s;
          }
        }
      }
    }
  }
  &__main {
    background-color: $primary-main;
    .navbar {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      .navbar-nav {
        li {
          position: relative;
          &.active {
            a {
              background-color: #87c80a;
            }
            &.dropdown {
              .sub-menu {
                li {
                  a {
                    background-color: transparent;
                  }
                  &.active {
                    a {
                      background-color: #87c80a;
                    }
                  }
                }
              }
            }
          }
          a {
            color: $primary-white;
            transition: 0.5s;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            text-transform: uppercase;
            padding: 13px 20px;
            &:hover {
              background-color: #87c80a;
            }
          }
          &.dropdown {
            a {
              padding-right: 40px;
            }
            &::after {
              content: "";
              position: absolute;
              right: 15px;
              top: 40%;
              width: 10px;
              height: 10px;
              background-image: url(../images/icons/next.png);
              transform: rotate(90deg);
              background-size: contain;
            }
            .sub-menu {
              position: absolute;
              z-index: 9;
              background: $primary-main;
              min-width: 220px;
              list-style: none;
              padding-left: 0px;
              margin-bottom: 0px;
              display: none;
              li {
                a {
                  padding: 13px 20px;
                }
                &:not(:last-child) {
                  a {
                    border-bottom: 1px solid #87c80a;
                  }
                }
              }
            }
            &:hover {
              .sub-menu {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.slick-arrow {
  z-index: 99;
  &.slick-next {
    &::before {
      content: "";
      background-image: url(../images/icons/flex-next.png);
      background-size: cover;
      width: 14px;
      height: 23px;
      display: block;
    }
  }
  &.slick-prev {
    &::before {
      content: "";
      background-image: url(../images/icons/flex-prev.png);
      background-size: cover;
      width: 14px;
      height: 23px;
      display: block;
    }
  }
}

.heading {
  h2,
  h1 {
    margin-bottom: 0px;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 32px;
  }
  .desc {
    text-align: center;
  }
}

.slider {
  &__list {
    margin-bottom: 0px;
    .item {
      img {
        width: 100%;
        max-height: 500px;
        object-fit: cover;
      }
    }

    .slick-arrow {
      z-index: 9;
      width: 40px;
      height: 40px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      background-color: #00000080;
      border-radius: 0;
      transition: 0.8s;
      border-radius: 0px;
      &.slick-prev {
        left: 40px;
        &::before {
          width: 25px;
          height: 25px;
          background-image: url(../images/icons/prev.png);
        }
      }
      &.slick-next {
        right: 40px;
        &::before {
          width: 25px;
          height: 25px;
          background-image: url(../images/icons/next.png);
        }
      }
    }
  }
}

.brand {
  &__slider {
    margin-bottom: 0px;
    .slick-slide {
      margin: 0 15px;
    }
    .slick-list {
      margin: 0 -15px;
    }
    .item {
      img {
        max-width: 100%;
      }
    }
    .slick-dots {
      position: absolute;
      margin: 0px;
      li {
        width: 10px;
        height: 10px;
        button {
          &:before {
            color: #ddd;
            background: #ddd;
            border-radius: 50%;
            opacity: 1;
            font-size: 0px;
            width: 10px;
            height: 10px;
            transition: 0.5s;
          }
          &:hover {
            &::before {
              color: $primary-main;
              background: $primary-main;
            }
          }
        }
        &.slick-active {
          button {
            &:before {
              color: $primary-main;
              background: $primary-main;
            }
          }
        }
      }
    }
    .slick-arrow {
      transition: 0.5s;
      display: none !important;
    }
    &:hover {
      .slick-arrow {
        display: inline-block !important;
      }
    }
  }
}

.product {
  .col-6 {
    margin-bottom: 30px;
  }
  .small-product {
    position: relative;
    .image {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 220px;
        object-fit: cover;
        transition: 0.5s;
      }
    }

    .title {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding: 10px;
      transition: 0.5s;
      background: #077d37;
      opacity: 0.8;
      h3 {
        font-size: 18px;
        // text-transform: uppercase;
        margin: 0px;
        line-height: 24px;
        a {
          color: $primary-white;
          text-decoration: none;
        }
      }
    }
    &:hover {
      .title {
        opacity: 1;
      }
      .image {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
  &__child {
    .item {
      .flip-card {
        overflow: hidden;
        position: relative;
        .flip-card-inner {
          position: relative;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: transform 0.8s;
          transform-style: preserve-3d;
          .flip-card-front,
          .flip-card-back {
            position: absolute;
            transform: rotateX(0deg);
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
          }
          .flip-card-front {
            background-color: #fff;
            color: black;
            position: relative;
            background-size: cover;
            img {
              width: 100%;
              height: 200px;
              object-fit: cover;
            }
            .title {
              position: absolute;
              bottom: 0;
              width: -webkit-fill-available;
              text-align: center;
              padding: 10px;
              transition: 0.5s;
              background: #077d37;
              opacity: 0.8;
              h3 {
                font-size: 18px;
                margin: 0px;
                line-height: 24px;
                font-weight: 600;
                color: #ffffff;
              }
            }
          }
          .flip-card-back {
            transform: rotateY(180deg);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $primary-main;
            .desc {
              color: #fff;
              position: absolute;
              top: 50%;
              margin: 0;
              font-size: 1rem;
              transform: translateY(-50%);
              padding: 10px;
              z-index: 9;
              text-align: center;
            }
          }
        }
        a {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 99;
          font-size: 0;
        }
      }
      .more {
        margin-top: 10px;
        text-align: center;
        a {
          background: #077d37;
          border-radius: 30px;
          display: inline-block;
          padding: 5px 20px;
          color: #fff;
          font-size: 14px;
          text-decoration: none;
          transition: 0.5s;
        }
      }
      &:hover {
        .flip-card {
          .flip-card-inner {
            transform: rotateY(180deg);
          }
        }
      }
      // .box-flip {
      //   position: relative;
      //   .box {
      //     transition: transform 0.6s;
      //     transform-style: preserve-3d;
      //     .image {
      //       img {
      //         width: 100%;
      //         height: 200px;
      //         object-fit: cover;
      //       }
      //     }
      //     .title {
      //       position: absolute;
      //       bottom: 0;
      //       width: 100%;
      //       text-align: center;
      //       padding: 10px;
      //       transition: 0.5s;
      //       background: #077d37;
      //       opacity: 0.8;
      //       h3 {
      //         font-size: 18px;
      //         margin: 0px;
      //         line-height: 24px;
      //         font-weight: 600;
      //         color: #ffffff;
      //       }
      //     }
      //     .desc {
      //       position: absolute;
      //       top: 0;
      //       left: 0;
      //       width: 100%;
      //       height: 100%;
      //       background-color: $primary-main;
      //       transform: rotateY(180deg);
      //       visibility: hidden;
      //       transition: all 0.5s 0.5s;
      //       p {
      //         color: #fff;
      //         position: absolute;
      //         top: 50%;
      //         width: 100%;
      //         margin: 0;
      //         font-size: 1rem;
      //         transform: translateY(-50%);
      //         padding: 10px;
      //         z-index: 9;
      //         text-align: center;
      //       }
      //     }
      //   }

      //   a {

      //   }
      // }
      // &:hover {
      //   .box-flip {
      //     .box {
      //       .title {
      //         display: none;
      //       }
      //       transform: rotateY(180deg);
      //       .desc {
      //         visibility: visible;
      //       }
      //     }
      //   }
      // }
      // .more {
      //   margin-top: 10px;
      //   text-align: center;
      //   a {
      //     background: $primary-main;
      //     border-radius: 30px;
      //     display: inline-block;
      //     padding: 5px 20px;
      //     color: #fff;
      //     font-size: 14px;
      //     text-decoration: none;
      //     transition: 0.5s;
      //     &:hover {
      //       background: #074e27;
      //     }
      //   }
      // }
    }
  }
}

.turfcare {
  background-color: $primary-main;

  .col-6 {
    margin-bottom: 30px;
  }
  .heading {
    h2 {
      margin-bottom: 0px;
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 32px;
      color: $primary-white;
    }
  }
  &__list {
    .image {
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 220px;
        object-fit: cover;
        transition: 0.5s;
      }
    }
    .item {
      &:hover {
        .image {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
    .title {
      h3 {
        font-size: 18px;
        margin: 0px;
        line-height: 24px;
        a {
          color: #fff;
          transition: 0.5s;
          text-decoration: none;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.team {
  background: #f6f6f6;
  &__slider {
    .item {
      text-align: center;
      .image {
        margin-bottom: 10px;
        img {
          border: 1px solid $primary-main;
          padding: 3px;
          width: 85px;
          height: 85px;
          object-fit: cover;
          border-radius: 50%;
          margin: auto;
        }
      }
      .name {
        h3 {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0px;
          a {
            text-decoration: none;
            color: #363636;
            transition: 0.5s;
            &:hover {
              color: $primary-main;
            }
          }
        }
      }
    }
  }
}

.member {
  &__list {
    .col-12 {
      margin-bottom: 30px;
    }
    .item {
      border: 1px solid #eaeef2;
      padding: 10px;
      border-radius: 6px;
      height: 100%;
      // transition-delay: 0.3s;
      transition: 0.5s;
      .image {
        margin-bottom: 15px;
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 6px;
        }
      }
      &:hover {
        -webkit-box-shadow: 0px 10px 10px #20283114;
        box-shadow: 0px 10px 10px #20283114;
        .desc {
          h3 {
            a {
              color: $primary-main;
            }
            &::before {
              background: $primary-main;
            }
          }
        }
      }
      .desc {
        h3 {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 6px;
          padding-bottom: 6px;
          font-weight: 600;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            height: 2px;
            width: 50px;
            background: transparent;
            transition: 0.5s;
          }
          a {
            text-decoration: none;
            color: #363636;
            transition: 0.5s;
          }
        }
        p {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.info {
  .image {
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .desc {
    h1 {
      font-size: 22px;
      line-height: 26px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 0px;
      font-size: 15px;
      line-height: 22px;
      text-align: justify;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

ul.pagination {
  justify-content: center;
  margin-bottom: 0px;
  li {
    a {
      transition: 0.5s;
      color: #363636;
      &:hover {
        background-color: $primary-main;
        color: #fff;
        border-color: $primary-main;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    &.active {
      a {
        background-color: $primary-main !important;
        color: #fff;
        border-color: $primary-main !important;
      }
    }
  }
}

.content {
  h1 {
    font-size: 26px;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  h5 {
    font-size: 16px;
    margin-bottom: 15px;
  }
  h6 {
    font-size: 14px;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    text-align: justify;
  }
  .featured-image {
    margin-bottom: 15px;
    img {
      width: 100%;
      margin-bottom: 0px;
    }
  }

  img {
    max-width: 100%;
  }
  img,
  iframe {
    display: block;
    margin: auto;
    margin-bottom: 10px;
  }
  .gallery {
    .col-lg-4 {
      margin-bottom: 30px;
    }
    .item {
      img {
        margin-bottom: 0px;
        height: 150px;
      }
    }
  }
}

#accordion {
  .card {
    .card-header {
      h5 {
        button {
          display: block;
          width: 100%;
          padding: 0;
          margin: 0;
          text-align: left;
          font-size: 16px;
          font-weight: 600;
          color: #363636;
          text-decoration: none;
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
    .card-body {
      p {
        font-size: 15px;
        line-height: 20px;
        text-align: justify;
      }
    }
  }
}

#breadcrumb {
  .breadcrumb {
    background-color: #fff;
    padding: 0px;
    li {
      color: $primary-main;
      font-size: 16px;
      font-weight: 500;
      a {
        color: #363636;
        transition: 0.5s;
        text-decoration: none;
        &:hover {
          color: $primary-main;
        }
      }
    }
  }
}

.services {
  &__list {
  }
}

.post-item {
  .col-12 {
    margin-bottom: 30px;
  }
  .item {
    &:hover {
      .image {
        img {
          transform: scale(1.2);
        }
      }
    }
    .image {
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;
      border-radius: 6px;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 6px;
        transition: 0.5s;
      }
    }
    &:hover {
      .desc {
        h3 {
          a {
            color: $primary-main;
          }
        }
      }
    }
    .desc {
      h3 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
        font-weight: 600;
        a {
          color: #363636;
          transition: 0.5s;
          text-decoration: none;
          &:hover {
            color: $primary-main;
          }
        }
      }
      p {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 0px;
        text-align: justify;
      }
    }
  }
}

.sidebar {
  h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid $primary-main;
  }
  aside {
    margin-bottom: 30px;
  }
  .recent-posts {
    .post {
      .image {
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 145px;
          object-fit: cover;
        }
      }
      .desc {
        h3 {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;
          font-weight: bold;
          a {
            color: #363636;
            transition: 0.5s;
            text-decoration: none;
            &:hover {
              color: $primary-main;
            }
          }
        }
        p {
          font-size: 15px;
          line-height: 20px;
          text-align: justify;
          margin-bottom: 0px;
        }
      }
      &:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #ddd;
      }
    }
  }
  .product-category {
    ul {
      list-style: none;
      padding-left: 0px;
      margin-bottom: 0px;
      li {
        a {
          color: $primary-dark;
          text-decoration: none;
          transition: 0.5s;
          position: relative;
          padding-left: 20px;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(../images/icons/right-arrow-angle.png);
            width: 12px;
            height: 12px;
            background-size: cover;
          }
          &:hover {
            color: $primary-main;
          }
        }
        &:not(:last-child) {
          margin-bottom: 8px;
          padding-bottom: 8px;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
}

.maps {
  iframe {
    margin-bottom: -10px;
  }
}

.contact {
  &__form {
    .col,
    .form-group {
      margin-bottom: 15px;
    }
    label {
      font-size: 15px;
      line-height: 20px;
      display: block;
      margin-bottom: 6px;
    }
    .form-control {
      font-size: 16px;
      height: 40px;
      border-radius: 6px;
      transition: 0.5s;
      line-height: 24px;
      &::placeholder {
        color: #999999;
      }
      &:focus {
        box-shadow: none;
        border-color: $primary-main;
      }
    }
    textarea {
      min-height: 90px;
    }
    button {
      height: 40px;
      padding: 5px 20px;
      font-size: 16px;
      border-radius: 30px;
      background-color: $primary-main;
      border-color: $primary-main;
      line-height: 24px;
      transition: 0.5s;
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: $primary-divider;
      }
      &:hover {
        background-color: $primary-divider;
        border-color: $primary-divider;
      }
    }
  }
}

.contactus {
  background-color: #f5f5f7;
  &__list {
    .col-12 {
      margin-bottom: 30px;
    }
    .item {
      ul {
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px;
        li {
          margin-bottom: 0px;
          // text-align: justify;
          a {
            color: #363636;
            text-decoration: none;
            transition: 0.5s;
            &:hover {
              color: $primary-main;
            }
          }
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
      h4 {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 15px;
        padding-bottom: 6px;
        border-bottom: 1px solid $primary-main;
        color: $primary-main;
      }
    }
  }
}

.gallery {
  .col-lg-3 {
    margin-bottom: 30px;
  }
  .item {
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
}

.ekko-lightbox {
  .modal-content {
    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }
    .modal-footer {
      text-align: center;
      display: block;
    }
  }
}

.footer {
  &__main {
    background-color: $primary-main;
    padding: 50px 0px;
    h4 {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 15px;
    }
    p {
      text-align: justify;
      line-height: 22px;
      color: #fff;
      margin-bottom: 0px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      li {
        font-size: 16px;
        color: #fff;
        span {
          display: block;
          font-weight: 600;
          position: relative;
          padding-left: 20px;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(../images/icons/right-arrow-angle.png);
            width: 12px;
            height: 12px;
            background-size: cover;
          }
        }
        a {
          text-decoration: none;
          color: #fff;
          transition: 0.5s;
          &:hover {
            opacity: 0.8;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid #87c80a;
          padding-bottom: 8px;
          margin-bottom: 8px;
        }
      }
      &.menu {
        li {
          a {
            position: relative;
            padding-left: 20px;
            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              background-image: url(../images/icons/right-arrow-angle.png);
              width: 12px;
              height: 12px;
              background-size: cover;
            }
          }
        }
      }
      &.follow {
        li {
          display: inline-block;
          a {
            width: 35px;
            height: 35px;
            text-align: center;
            background: #e6e0dd;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
          &:not(:last-child) {
            margin-bottom: 0px;
            margin-right: 6px;
            border: none;
            margin-top: 6px;
          }
        }
      }
    }
  }
  &__copyright {
    text-align: center;
    background: #074e27;
    padding: 10px 0px;
    color: #fff;
    font-size: 13px;
    letter-spacing: 1px;
  }
}
.back-to-top {
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 1.2em;
  margin: 1.2em;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-image: url(../images/icons/next.png);
  transform: rotate(-90deg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
}
.tags {
  h4 {
  }
  ul {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 15px;
    li {
      margin-bottom: 10px;
      display: inline-block;
      &:not(:last-child) {
        margin-right: 5px;
      }
      a {
        background: gray;
        color: #fff;
        padding: 5px 10px;
        font-size: 13px;
        line-height: 18px;
        text-decoration: none;
        border-radius: 0px 6px 6px 0px;
        margin-left: 18px;
        position: relative;
        transition: 0.5s;

        &::before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          margin-left: 0;
          vertical-align: middle;
          border-top: 13px solid transparent;
          border-right: 18px solid;
          border-right-color: gray;
          border-left: 0 solid transparent;
          border-bottom: 14px solid transparent;
          position: absolute;
          top: 0;
          transition: 0.5s;
        }
        &::after {
          content: "";
          position: absolute;
          left: -3px;
          top: 50%;
          width: 5px;
          height: 5px;
          background-color: #fff;
          border-radius: 50%;
          transform: translateY(-50%);
          transition: 0.5s;
        }
        &:hover {
          background-color: #077d37;
          &::before {
            border-right-color: #077d37;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .container {
    max-width: 100%;
  }
  .header {
    &__main {
      button {
        padding: 8px 0px;
        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
      }
      .navbar {
        .navbar-nav {
          li {
            a {
              padding: 12px 0px;
              &:hover {
                background-color: transparent;
                color: #87c80a;
              }
            }
            &:not(:last-child) {
              a {
                border-bottom: 1px solid #87c80a;
              }
            }
            &.active {
              a {
                background-color: transparent;
                color: #87c80a;
              }
              &.dropdown {
                .sub-menu {
                  li {
                    a {
                      background-color: transparent;
                      color: #fff;
                    }
                    &.active {
                      a {
                        background-color: transparent;
                        color: #87c80a;
                      }
                    }
                  }
                }
              }
            }
            &.dropdown {
              a {
                width: 90%;
              }
              &::after {
                display: none;
              }
              .show {
                position: absolute;
                width: 30px;
                height: 30px;
                background: #074e27;
                right: 0;
                z-index: 999;
                top: 7px;
                background-image: url(../images/icons/next.png);
                transform: rotate(90deg);
                background-repeat: no-repeat;
                background-size: 50%;
                background-position: center center;
              }
              .sub-menu {
                position: relative;
                padding-left: 30px;
                transition: 10s;
                li {
                  a {
                    // width: 100%;
                    padding: 12px 0px;
                    padding-right: 0px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .slider {
    &__list {
      .slick-arrow {
        .slick-prev {
          left: 20px;
        }
        .slick-next {
          right: 20px;
        }
      }
    }
  }
  .product {
    .small-product {
      .image {
        img {
          height: 255px;
        }
      }
    }
  }
  .team {
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
  .footer {
    .col-12:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  .brand {
    &__slider {
      .slick-arrow {
        display: inline-block !important;
        &.slick-next {
          right: 0px;
        }
        &.slick-prev {
          left: 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .heading {
    h2,
    h1 {
      font-size: 22px !important;
    }
  }
  .header {
    &__top {
      .logo {
        margin-bottom: 15px;
      }
      .search {
        form {
          button {
            height: 35px;
          }
          .form-group {
            .form-control {
              height: 35px;
            }
          }
        }
      }
    }
  }
  .slider {
    &__list {
      .slick-arrow {
        width: 32px;
        height: 32px;
        &.slick-prev {
          left: 20px;
          &::before {
            width: 10px;
            height: 12px;
          }
        }
        &.slick-next {
          right: 20px;
          &::before {
            width: 10px;
            height: 12px;
          }
        }
      }
    }
  }
  .product {
    .small-product {
      .image img {
        height: 160px;
      }
      .title {
        h3 {
          font-size: 16px;
        }
      }
    }
    &__child {
      .item {
        &:hover {
          .flip-card {
            .flip-card-inner {
              transform: rotateY(0);
              .flip-card-front {
                display: block;
              }
            }
          }
        }
        .more a {
          padding: 4px 15px;
          font-size: 13px;
        }
        .flip-card {
          .flip-card-inner {
            .flip-card-front {
              img {
                width: 100%;
                height: 130px;
                object-fit: cover;
              }
              .title {
                position: relative;
                background: transparent;
                opacity: 1;
                h3 {
                  color: #333;
                }
              }
            }
            .flip-card-back {
              transform: rotateY(0);
              position: relative;
              top: auto;
              left: auto;
              width: 100%;
              height: 100%;
              background-color: transparent;
              .desc {
                color: #333;
                position: relative;
                top: 0;
                transform: translateY(0);
                text-align: center;
                padding: 0;
                p {
                  text-align: center;
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  .turfcare {
    &__list {
      .image img {
        height: 160px;
      }
      .title {
        h3 {
          font-size: 16px;
        }
      }
    }
  }
  .info .image {
    margin-bottom: 10px;
  }
  .sidebar {
    margin-top: 30px;
    .recent-posts .post .image img {
      height: 186px;
    }
  }
  .content {
    h1 {
      font-size: 22px;
    }

    iframe {
      height: 190px;
    }
  }
  .product {
    &__child {
      .item {
        .image img {
          height: 130px;
        }
        .desc {
          h3 {
            font-size: 18px;
          }
          p {
            margin-bottom: 0px;
            text-align: left;
          }
        }
      }
    }
  }
  .gallery .item img {
    height: 130px;
  }
  .contact {
    &__form {
      .offset-sm-2 {
        padding: 0px !important;
      }
      .form-row {
        display: block !important;
      }
    }
  }

  .footer {
    &__main {
      h4 {
        font-size: 18px;
      }
      p {
        font-size: 15px;
      }
      ul {
        li {
          font-size: 15px;
        }
      }
    }
  }
  #breadcrumb {
    .breadcrumb {
      overflow-x: scroll;
      overflow-y: hidden;
      display: inline-block;
      white-space: nowrap;
      margin-bottom: 0px;
      li {
        display: inline-block;
      }
    }
  }
}
